/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// Filters.js
import React, { useState, useEffect, useRef } from "react";
import airlinesName from "../../Components/AirlineName/AirlineName";
import axios from "axios";
import filter from "../../Resources/assest/img/listing/filter.png";
import stop from "../../Resources/assest/img/listing/stop.png";
import depart from "../../Resources/assest/img/listing/depart.png";
import clock1 from "../../Resources/assest/img/listing/0-6.png";
import clock2 from "../../Resources/assest/img/listing/6-12.png";
import clock3 from "../../Resources/assest/img/listing/12-6.png";
import clock4 from "../../Resources/assest/img/listing/6-0.png";
import returnn from "../../Resources/assest/img/listing/return.png";
import fares from "../../Resources/assest/img/listing/fares.png";
import airline from "../../Resources/assest/img/listing/airline.png";

const Filters = ({
  flights,
  filteredResultsCount,
  onFilterChange,
  mobileFilter,
  initialFormValues,
}) => {
  const [stops, setStops] = useState({
    direct: true, // Initially checked
    oneStop: true, // Initially checked
    twoPlusStops: true, // Initially checked
  });

  const [priceRange, setPriceRange] = useState([0, 1000]);

  // State for selected airlines
  const [selectedAirlines, setSelectedAirlines] = useState([]);
  // const [selectAllAirlines, setSelectAllAirlines] = useState(true);
  const [selectedLayoverAirports, setSelectedLayoverAirports] = useState([]);
  const [layoverAirportsWithNames, setLayoverAirportsWithNames] = useState({});
  const [returnLayoverAirportsWithNames, setReturnLayoverAirportsWithNames] =
    useState({});
  const [selectedReturnLayoverAirports, setSelectedReturnLayoverAirports] =
    useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showMoreAirports, setShowMoreAirports] = useState(false);
  const [showMoreReturnAirports, setShowMoreReturnAirports] = useState(false);

  // State for selected time slots
  const [selectedTimeSlots, setSelectedTimeSlots] = useState(new Set());
  const [selectedTimeSlotsForReturn, setSelectedTimeSlotsForReturn] = useState(
    new Set()
  );

  // Function to fetch airport name from API based on airport code
  // const fetchAirportName = async (airport) => {
  //     try {
  //         console.log("PAyload",airport)
  //         const response = await axios.get(
  //             `https://www.tgsapi.com/flights/CrossAirportList?data=${airport}&authcode=farehuts`
  //         );
  //         console.log("Direct response",response)
  //         const airportData = response.data.find(
  //             (airport) => airport.airportCode === airport
  //         );
  //         console.log("API Response",airportData)
  //         return airportData ? airportData.airportName : airport; // Return airport name or fallback to code
  //     } catch (error) {
  //         console.error("Error fetching airport data:", error);
  //         return airport; // Fallback in case of an error
  //     }
  // };

  const timeSlots = [
    { label: "00-06", start: 0, end: 6, image: clock1 },
    { label: "06-12", start: 6, end: 12, image: clock2 },
    { label: "12-18", start: 12, end: 18, image: clock3 },
    { label: "18-24", start: 18, end: 24, image: clock4 },
  ];
  const timeSlotsForReturn = [
    { label: "00-06", start: 0, end: 6, image: clock1 },
    { label: "06-12", start: 6, end: 12, image: clock2 },
    { label: "12-18", start: 12, end: 18, image: clock3 },
    { label: "18-24", start: 18, end: 24, image: clock4 },
  ];

  const handleStopChange = (e) => {
    const { name, checked } = e.target;
    setStops((prev) => ({ ...prev, [name]: checked }));
  };

  // const handleTimeSlotChange = (index) => {
  //     const updatedSlots = new Set(selectedTimeSlots);
  //     if (updatedSlots.has(index)) {
  //       updatedSlots.delete(index);
  //     } else {
  //       updatedSlots.add(index);
  //     }
  //     setSelectedTimeSlots(updatedSlots);
  //   };

  //   const handleTimeSlotForReturnChange = (index) => {
  //     const updatedSlots = new Set(selectedTimeSlotsForReturn);
  //     if (updatedSlots.has(index)) {
  //       updatedSlots.delete(index);
  //     } else {
  //       updatedSlots.add(index);
  //     }
  //     setSelectedTimeSlotsForReturn(updatedSlots);
  //   };
  const handleTimeSlotChange = (index) => {
    setSelectedTimeSlots(new Set([index]));
  };

  // Allow selecting only one time slot for return
  const handleTimeSlotForReturnChange = (index) => {
    setSelectedTimeSlotsForReturn(new Set([index]));
  };

  // Function to calculate minimum price for each stop type
  const calculatePriceForStops = (numStops) => {
    const filteredFlights = flights.filter((flight) => {
      const leg1Stops =
        flight.leg1.segments.length > 0 ? flight.leg1.segments.length - 1 : 0;
      const leg2Stops = flight.leg2
        ? flight.leg2.segments.length > 0
          ? flight.leg2.segments.length - 1
          : 0
        : 0;
      const totalStops = leg1Stops + leg2Stops;

      return totalStops === numStops;
    });

    if (filteredFlights.length > 0) {
      return Math.min(
        ...filteredFlights.map((flight) => flight.price.totalAmount)
      );
    }

    return null; // If no flights match, return null
  };
  // Calculate the lowest price for each stop category
  // Calculate the lowest price for each stop category
  const calculateStopPrices = (flights) => {
    const stopPrices = {
      direct: null,
      oneStop: null,
      twoPlusStop: null,
    };

    flights.forEach((flight) => {
      const totalStops =
        flight.leg1.segments.length -
        1 +
        (flight.leg2 ? flight.leg2.segments.length - 1 : 0);

      if (totalStops === 0) {
        stopPrices.direct = Math.min(
          stopPrices.direct ?? Infinity,
          flight.price.totalAmount
        );
      } else if (totalStops === 1) {
        stopPrices.oneStop = Math.min(
          stopPrices.oneStop ?? Infinity,
          flight.price.totalAmount
        );
      } else if (totalStops >= 2) {
        stopPrices.twoPlusStop = Math.min(
          stopPrices.twoPlusStop ?? Infinity,
          flight.price.totalAmount
        );
      }
    });

    return stopPrices;
  };

  const stopPrices = calculateStopPrices(flights);

  // Get prices for each stop type
  const priceForDirect = calculatePriceForStops(0); // 0 stops
  const priceForOneStop = calculatePriceForStops(1); // 1 stop
  const priceForTwoPlusStops = calculatePriceForStops(2); // 2 or more stops

  const minPrice = 0;
  const maxPrice = 1000;

  const sliderRef = useRef(null);

  // Function to calculate position
  const calculatePosition = (clientX) => {
    const slider = sliderRef.current;
    if (!slider) return 0;

    const rect = slider.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    let percentage = (offsetX / rect.width) * 100;

    // Clamp the percentage between 0 and 100
    percentage = Math.max(0, Math.min(percentage, 100));
    return Math.round((percentage / 100) * (maxPrice - minPrice) + minPrice);
  };

  const handleMouseDown = (isLower) => (e) => {
    const onMouseMove = (event) => {
      const newPosition = calculatePosition(event.clientX);
      setPriceRange((prevRange) => {
        if (isLower) {
          return [Math.min(newPosition, prevRange[1] - 10), prevRange[1]];
        } else {
          return [prevRange[0], Math.max(newPosition, prevRange[0] + 10)];
        }
      });
    };

    const onMouseUp = () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  // Function to extract unique airlines and their prices
  const getAirlinesWithLowestPrices = () => {
    const airlines = {};

    flights.forEach((flight) => {
        const airlineCodes = Array.from(
            new Set([
                ...(flight.leg1?.segments.map((s) => s.airlineCode.toUpperCase()) || []),
                ...(flight.leg2?.segments.map((s) => s.airlineCode.toUpperCase()) || [])
            ])
        );

        const price = flight.price?.totalAmount;

        airlineCodes.forEach((airlineCode) => {
            if (airlineCode && typeof price === "number" && price > 0) {
                if (!airlines[airlineCode] || price < airlines[airlineCode].price) {
                    airlines[airlineCode] = { code: airlineCode, price };
                }
            }
        });
    });

    // Convert to array and sort by price in ascending order
    return Object.values(airlines).sort((a, b) => a.price - b.price);
};

  const airlinesWithLowestPrices = getAirlinesWithLowestPrices();
  
  // Extract layover airports (departure airports for `segment[1]` of `leg1`)
  const getLayoverAirports = () => {
    const layoverAirports = {};

    flights.forEach((flight) => {
      if (flight.leg1.segments.length > 1) {
        const layoverAirportCode = flight.leg1.segments[1].departureAirportCode;
        if (!layoverAirports[layoverAirportCode]) {
          layoverAirports[layoverAirportCode] = layoverAirportCode; // Store airport code
        }
      }
    });

    return Object.values(layoverAirports); // Return array of unique layover airport codes
  };

  const layoverAirports = getLayoverAirports();

  const getReturnLayoverAirports = () => {
    const returnLayoverAirports = {};

    flights?.forEach((flight) => {
      if (flight?.leg2?.segments?.length > 1) {
        const returnLayoverAirportCode =
          flight?.leg2?.segments[1]?.departureAirportCode;
        if (!returnLayoverAirports[returnLayoverAirportCode]) {
          returnLayoverAirports[returnLayoverAirportCode] =
            returnLayoverAirportCode; // Store airport code
        }
      }
    });

    return Object.values(returnLayoverAirports); // Return array of unique layover airport codes
  };

  const returnLayoverAirports = getReturnLayoverAirports();

  const visibleAirlines = showMore
    ? airlinesWithLowestPrices
    : airlinesWithLowestPrices.slice(0, 5);

  // useEffect(() => {
  //     if (visibleAirlines.length > 0) {
  //         setSelectedAirlines(visibleAirlines.map((airline) => airline.code)); // Select all airlines initially
  //         setSelectAllAirlines(true); // Ensure "Select All" is checked
  //     }
  // }, []);

  // const handleSelectAllChange = () => {
  //     setSelectAllAirlines((prev) => {
  //         const newSelectAll = !prev;
  //         // Update selected airlines based on "Select All" state
  //         setSelectedAirlines(newSelectAll ? visibleAirlines.map((airline) => airline.code) : []);
  //         return newSelectAll;
  //     });
  // };

  // Handle airline selection change
  const handleAirlineChange = (e) => {
    const { value, checked } = e.target;
    setSelectedAirlines((prev) =>
      checked ? [...prev, value] : prev.filter((airline) => airline !== value)
    );
  };
  // const handleAirlineChange = (e) => {
  //     const { value, checked } = e.target;
  //     setSelectedAirlines((prevSelectedAirlines) =>
  //         checked
  //             ? [...prevSelectedAirlines, value]
  //             : prevSelectedAirlines.filter((airline) => airline !== value)
  //     );

  //     // If any checkbox is unchecked, uncheck "Select All"
  //     if (!checked) setSelectAllAirlines(false);

  //     // If all checkboxes are checked after the change, check "Select All"
  //     setSelectAllAirlines((prevSelectAll) => {
  //         if (
  //             checked &&
  //             visibleAirlines.every((airline) =>
  //                 [...prevSelectedAirlines, value].includes(airline.code)
  //             )
  //         ) {
  //             return true;
  //         }
  //         return prevSelectAll;
  //     });
  // };

  // useEffect(() => {
  //     const fetchAllLayoverAirportNames = async () => {
  //         const updatedAirports = {};

  //         // Fetch all airport names in parallel using Promise.all
  //         const airportNamePromises = layoverAirports.map(async (airport) => {
  //             const airportName = await fetchAirportName(airport);
  //             updatedAirports[airport] = airportName;
  //         });

  //         // Wait for all promises to resolve
  //         await Promise.all(airportNamePromises);

  //         // Update state only after all names are fetched
  //         setLayoverAirportsWithNames(updatedAirports);
  //         console.log(layoverAirportsWithNames)
  //     };
  //     console.log(layoverAirportsWithNames)
  //     if (layoverAirports.length > 0) {
  //         fetchAllLayoverAirportNames();
  //     }
  // }, [layoverAirports]);

  // useEffect(() => {
  //     const fetchLayoverAirports = async () => {
  //         const updatedAirports = {};
  //         for (const airport of returnLayoverAirports) {
  //             const airportName = await fetchAirportName(airport);
  //             updatedAirports[airport] = airportName;
  //         }
  //         setReturnLayoverAirportsWithNames(updatedAirports);
  //     };

  //     if (returnLayoverAirports.length > 0) {
  //         console.log("it is called for return", returnLayoverAirportsWithNames)
  //         fetchLayoverAirports();
  //     }
  // }, [returnLayoverAirports]);

  // Handle layover airport filter changes
  const handleLayoverAirportChange = (e) => {
    const { value, checked } = e.target;
    setSelectedLayoverAirports((prev) =>
      checked ? [...prev, value] : prev.filter((airport) => airport !== value)
    );
  };

  const handleReturnLayoverAirportChange = (e) => {
    const { value, checked } = e.target;
    setSelectedReturnLayoverAirports((prev) =>
      checked ? [...prev, value] : prev.filter((airport) => airport !== value)
    );
  };

  // Function to filter flights
  const filterFlights = () => {
    return flights.filter((flight) => {
      const leg1Stops = flight.leg1.segments.length > 0 ? flight.leg1.segments.length - 1 : 0;
      const leg2Stops = flight.leg2
        ? flight.leg2.segments.length > 0
          ? flight.leg2.segments.length - 1
          : 0
        : 0;
      const totalStops = leg1Stops + leg2Stops;
    
      const matchesStops =
        (stops.direct && totalStops === 0) ||
        (stops.oneStop && totalStops === 1) ||
        (stops.twoPlusStops && totalStops >= 2);
    
      // const price = flight.price.totalAmount;
      // const matchesPrice = price >= priceRange[0] && price <= priceRange[1];
    
      const departureTime = new Date(flight.leg1.segments[0].departureDateTime).getHours();
      const matchesTimeSlot =
        selectedTimeSlots.size === 0 ||
        Array.from(selectedTimeSlots).some((slot) => {
          const { start, end } = timeSlots[slot];
          return departureTime >= start && departureTime < end;
        });
    
      const returnTime = flight.leg2
        ? new Date(flight.leg2.segments[0].departureDateTime).getHours()
        : null;
      const matchesTimeSlotForReturn =
        flight.leg2 &&
        (selectedTimeSlotsForReturn.size === 0 ||
          Array.from(selectedTimeSlotsForReturn).some((slot) => {
            const { start, end } = timeSlotsForReturn[slot];
            return returnTime >= start && returnTime < end;
          }));
    
      // Apply Airline Filters
      const flightAirlines = [
        ...new Set([
          ...(flight.leg1?.segments.map((s) => s.airlineCode.toUpperCase()) || []),
          ...(flight.leg2?.segments.map((s) => s.airlineCode.toUpperCase()) || []),
        ]),
      ];
    
      const matchesAirline =
        selectedAirlines.length === 0 ||
        selectedAirlines.some((airline) => flightAirlines.includes(airline));
    
      const layoverAirport =
        flight.leg1.segments.length > 1 ? flight.leg1.segments[1].departureAirportCode : null;
      const matchesLayoverAirport =
        selectedLayoverAirports.length === 0 ||
        (layoverAirport && selectedLayoverAirports.includes(layoverAirport));
    
      const returnLayoverAirport =
        flight?.leg2?.segments.length > 1 ? flight?.leg2?.segments[1].departureAirportCode : null;
      const matchesReturnLayoverAirport =
        selectedReturnLayoverAirports.length === 0 ||
        (returnLayoverAirport && selectedReturnLayoverAirports.includes(returnLayoverAirport));
    
      // Debugging
      // console.log("Flight Airlines:", flightAirlines);
      // console.log("Selected Airlines:", selectedAirlines);
      // console.log("Matches Airline:", matchesAirline);
      // console.log("Matches Stops:", matchesStops);
      // console.log("Matches Price:", matchesPrice);
      // console.log("Matches Time Slot:", matchesTimeSlot);
      // console.log("Matches Time Slot for Return:", matchesTimeSlotForReturn);
      // console.log("Matches Layover Airport:", matchesLayoverAirport);
      // console.log("Matches Return Layover Airport:", matchesReturnLayoverAirport);
    
      const isMatch =
        matchesStops &&
        // matchesPrice &&
        matchesTimeSlot &&
        matchesTimeSlotForReturn &&
        matchesAirline &&
        matchesLayoverAirport &&
        matchesReturnLayoverAirport;
    
      console.log("Final Match:", isMatch);
    
      return isMatch;
    });
    
  };

  useEffect(() => {
    console.log("airlinesWithLowestPrices", airlinesWithLowestPrices)
    const filteredFlights = filterFlights();
    onFilterChange(filteredFlights); // Pass the filtered flights to the parent
  }, [
    stops,
    // priceRange,
    selectedTimeSlots,
    selectedTimeSlotsForReturn,
    selectedAirlines,
    selectedLayoverAirports,
    selectedReturnLayoverAirports,
    flights,
  ]);

  const handleSliderChange = (newRange) => {
    setPriceRange(newRange);
  };

  const handleSliderDrag = (e, isLowerBound) => {
    const newValue = Math.max(
      0,
      Math.min(1000, (e.clientX / window.innerWidth) * 1000)
    ); // Ensure within bounds
    setPriceRange(
      (prevRange) =>
        isLowerBound
          ? [Math.min(newValue, prevRange[1]), prevRange[1]] // Adjust lower bound
          : [prevRange[0], Math.max(newValue, prevRange[0])] // Adjust upper bound
    );
  };

  const handleDragEnd = () => {
    // Add any logic needed when the drag ends
    // console.log("Drag ended with range:", priceRange);
  };

  const handleReset = () => {
    setStops({ direct: false, oneStop: false, twoPlusStops: false });
    setPriceRange([0, 1000]);
    setSelectedTimeSlots(new Set());
    setSelectedTimeSlotsForReturn(new Set());
    setSelectedAirlines([]);
    setSelectedLayoverAirports([]);
    setSelectedReturnLayoverAirports([]);
  };

  const clearStopFilters = () => {
    setStops({ direct: false, oneStop: false, twoPlusStops: false });
  };

  const clearGoTimeFilters = () => {
    setSelectedTimeSlots(new Set());
  };

  const clearReturnTimeFilters = () => {
    setSelectedTimeSlotsForReturn(new Set());
  };

  const clearAirlineFilters = () => {
    setSelectedAirlines([]);
  };

  const clearLayoverFilters = () => {
    setSelectedLayoverAirports([]);
  };

  const clearReturnLayoverFilters = () => {
    setSelectedReturnLayoverAirports([]);
  };

  return (
    <>
      <div class="col-lg-3 hutd67 det4r566">
        <div class=" dxgewet sedtgsea">
          <div class="d-flex justify-content-end ">
            <p class="uyre6k" onClick={handleReset}>
              Reset All Filters
            </p>
          </div>
          <div class="right-box d-flex align-items-start ">
            <img class="icon-lerft" src={filter} alt="" />
            <div class="right-inner-box">
              <h6 class="mb-2 font-clrf">Filter results</h6>
              <p class="font-re-s gtuj">Found {filteredResultsCount} Result.</p>
            </div>
          </div>
          <div class="right-box d-flex align-items-start ">
            <img class="icon-lerft" src={stop} alt="" />
            <div class="right-inner-box">
              <h6 class="mb-2 font-clrf">
                Stop(s)
                {(stops.direct || stops.oneStop || stops.twoPlusStops) && (
                  <span className="ut48r" onClick={clearStopFilters}>
                    clear
                  </span>
                )}
              </h6>
              <div class="gtuj1">
                <div class="d-flex font-re-s align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <input
                      class="form-check-input dfyhdry me-1"
                      name="direct"
                      type="checkbox"
                      checked={stops.direct}
                      disabled={stopPrices.direct === null}
                      onChange={handleStopChange}
                      id="flexCheckChecked"
                    />
                    <p class="mb-0 ms-1">Direct</p>
                  </div>
                  <p class="mb-0 ms-1">
                    {stopPrices.direct
                      ? `$${Math.round(stopPrices.direct.toFixed(2))}`
                      : "N/A"}
                    {/*<sup>00</sup>*/}
                  </p>
                </div>
                <div class="d-flex font-re-s align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <input
                      class="form-check-input dfyhdry me-1"
                      name="oneStop"
                      type="checkbox"
                      checked={stops.oneStop}
                      disabled={stopPrices.oneStop === null}
                      onChange={handleStopChange}
                      id="flexCheckChecked"
                    />
                    <p class="mb-0 ms-1">1 stop</p>
                  </div>
                  <p class="mb-0 ms-1">
                    {stopPrices.oneStop
                      ? `$${Math.round(stopPrices.oneStop.toFixed(2))}`
                      : "N/A"}
                    {/*<sup>00</sup>*/}
                  </p>
                </div>
                <div class="d-flex font-re-s align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <input
                      class="form-check-input dfyhdry me-1"
                      type="checkbox"
                      name="twoPlusStops"
                      checked={stops.twoPlusStops}
                      onChange={handleStopChange}
                      id="flexCheckChecked"
                      disabled={stopPrices.twoPlusStop === null} // Disable if no eligible flights
                    />
                    <p class="mb-0 ms-1">2+ stop</p>
                  </div>
                  <p class="mb-0 ms-1">
                    {stopPrices.twoPlusStop
                      ? `$${Math.round(stopPrices.twoPlusStop.toFixed(2))}`
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="right-box d-flex align-items-start ">
            <img class="icon-lerft" src={depart} alt="" />
            <div class="right-inner-box">
              <h6 class="mb-2 font-clrf">
                Departure From{" "}{initialFormValues?.segment[0]?.originAirport}
                <span
                  className="ut48r"
                  style={{ cursor: "pointer" }}
                  onClick={clearGoTimeFilters}
                >
                  clear
                </span>
              </h6>
              <p class="font-re-s gtuj"></p>
              <div class="row gtuj">
                {timeSlots.map((slot, index) => (
                  <div class="col-lg-3 ps-0" key={index}>
                    <div class="ugbj">
                      <button
                        className={`dertotg ${
                          selectedTimeSlots.has(index.toString())
                            ? "selected dorht"
                            : ""
                        }`}
                        disabled={filteredResultsCount === 0}
                        onClick={() => handleTimeSlotChange(index.toString())}
                      >
                        <img src={slot.image} alt={slot.label} />
                      </button>
                      <p class="swtsws456">{slot.label}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div class="right-box d-flex align-items-start ">
            <img class="icon-lerft" src={returnn} alt="" />
            <div class="right-inner-box">
              <h6 class="mb-2 font-clrf">
                Return From{" "}{initialFormValues?.segment[0]?.destinationAirport}
                <span
                  className="ut48r"
                  style={{ cursor: "pointer" }}
                  onClick={clearReturnTimeFilters}
                >
                  clear
                </span>
              </h6>
              <p class="font-re-s gtuj"></p>
              <div class="row gtuj">
                {timeSlotsForReturn.map((slot, index) => (
                  <div class="col-lg-3 ps-0">
                    <div class="ugbj">
                      <button
                        className={`dertotg ${
                          selectedTimeSlotsForReturn.has(index.toString())
                            ? "selected dorht"
                            : ""
                        }`}
                        onClick={() =>
                          handleTimeSlotForReturnChange(index.toString())
                        }
                        disabled={filteredResultsCount === 0}
                      >
                        <img src={slot.image} alt={slot.label} />
                      </button>
                      <p class="swtsws456">{slot.label}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div class="right-box d-flex align-items-start ">
                        <img class="icon-lerft" src={fares} alt="" />
                        <div class="right-inner-box">
                            <h6 class="mb-2 font-clrf">Fares <span className='ut48r'>clear</span></h6>
                            <div class="d-flex justify-content-between  ">
                                <p class="aopi46">{`$${priceRange[0]}`}{/*<sup>00</sup></p>
                                <p class="aopi46">{`$${priceRange[1]}`}{/*<sup>00</sup></p>
                            </div>
                            <div class="rela">
                                <div class="stgt-line"></div>
                                <div class="cirlfjg" style={{ left: `${(priceRange[0] / maxPrice) * 100}%` }}
                                    onMouseDown={handleMouseDown(true)} draggable={filteredResultsCount > 0}
                                // onDrag={(e) => handleSliderDrag(e, true)} // Drag for lower bound
                                //                         onDragEnd={handleDragEnd}
                                ></div>
                                <div
                                    className="cirlfjg"
                                    style={{ left: `${(priceRange[1] / maxPrice) * 100}%` }}
                                    onMouseDown={handleMouseDown(false)}
                                    draggable={filteredResultsCount > 0}
                                // onDrag={(e) => handleSliderDrag(e, false)} // Drag for upper bound
                                // onDragEnd={handleDragEnd}
                                />
                            </div>
                        </div>
                    </div> */}
          <div class="right-box d-flex align-items-start ">
            <img class="icon-lerft" src={airline} alt="" />
            <div class="right-inner-box">
              <h6 class="mb-2 font-clrf">
                Airlines{" "}
                <span
                  className="ut48r"
                  style={{ cursor: "pointer" }}
                  onClick={clearAirlineFilters}
                >
                  clear
                </span>
              </h6>
              <div className="d-flex font-re-s align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  {/*<input
                                        className="form-check-input dfyhdry me-1"
                                        type="checkbox"
                                        checked={selectAllAirlines}
                                        onChange={handleSelectAllChange}
                                        disabled={filteredResultsCount === 0}
                                        id="selectAllCheckbox"
                                    />*/}
                  {/* <p className="mb-0 ms-1">Select All</p> */}
                </div>
              </div>
              {visibleAirlines.map((airline, index) => (
                <div
                  className="d-flex font-re-s align-items-center justify-content-between"
                  key={index}
                >
                  <div className="d-flex align-items-center">
                    <input
                      className="form-check-input dfyhdry me-1"
                      type="checkbox"
                      value={airline.code}
                      onChange={handleAirlineChange}
                      id={`checkbox-${airline.code}`}
                      checked={selectedAirlines.includes(airline.code)}
                    />
                    <p className="mb-0 ms-1">{airlinesName[airline.code]}</p>
                  </div>
                  <p className="mb-0 ms-1">${airline.price.toLocaleString()}</p>
                </div>
              ))}
              {airlinesWithLowestPrices.length > 5 && (
                <button
                  className="btn btn-link p-0 mt-2"
                  onClick={() => setShowMore((prev) => !prev)}
                >
                  {showMore ? "Show Less" : "Show More"}
                </button>
              )}
            </div>
          </div>
          
        </div>
      </div>

      {mobileFilter ? (
        <div class="modal-dialog modal-fullscreen-sm-down sioee">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Apply Filter
              </h5>
            </div>
            <div class="modal-body">
              <div class=" dxgewet sedtgsea">
                <div class="d-flex justify-content-end ">
                  <p class="uyre6k" onClick={handleReset}>
                    Reset
                  </p>
                </div>
                <div class="right-box d-flex align-items-start ">
                  <img class="icon-lerft" src={filter} alt="" />
                  <div class="right-inner-box">
                    <h6 class="mb-2 font-clrf">Filter results </h6>
                    <p class="font-re-s gtuj">
                      Found {filteredResultsCount} Result.
                    </p>
                  </div>
                </div>
                <div class="right-box d-flex align-items-start ">
                  <img class="icon-lerft" src={stop} alt="" />
                  <div class="right-inner-box">
                    <h6 class="mb-2 font-clrf">Stop(s)</h6>
                    <div class="gtuj1">
                      <div class="d-flex font-re-s align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <input
                            class="form-check-input dfyhdry me-1"
                            name="direct"
                            type="checkbox"
                            checked={stops.direct}
                            onChange={handleStopChange}
                            id="flexCheckChecked"
                          />
                          <p class="mb-0 ms-1">Direct</p>
                        </div>
                        <p class="mb-0 ms-1">
                          {priceForDirect !== null
                            ? `$${priceForDirect.toFixed(2)}`
                            : "N/A"}
                          {/*<sup>00</sup>*/}
                        </p>
                      </div>
                      <div class="d-flex font-re-s align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <input
                            class="form-check-input dfyhdry me-1"
                            name="oneStop"
                            type="checkbox"
                            checked={stops.oneStop}
                            onChange={handleStopChange}
                            id="flexCheckChecked"
                          />
                          <p class="mb-0 ms-1">1 stop</p>
                        </div>
                        <p class="mb-0 ms-1">
                          {priceForOneStop !== null
                            ? `$${priceForOneStop.toFixed(2)}`
                            : "N/A"}
                          {/*<sup>00</sup>*/}
                        </p>
                      </div>
                      <div class="d-flex font-re-s align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <input
                            class="form-check-input dfyhdry me-1"
                            type="checkbox"
                            name="twoPlusStops"
                            checked={stops.twoPlusStops}
                            onChange={handleStopChange}
                            id="flexCheckChecked"
                          />
                          <p class="mb-0 ms-1">2+ stop</p>
                        </div>
                        <p class="mb-0 ms-1">
                          {priceForTwoPlusStops !== null
                            ? `$${priceForTwoPlusStops.toFixed(2)}`
                            : "N/A"}
                          {/*<sup>00</sup>*/}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right-box d-flex align-items-start ">
                  <img class="icon-lerft" src={depart} alt="" />
                  <div class="right-inner-box">
                    <h6 class="mb-2 font-clrf">Departure From</h6>
                    <p class="font-re-s gtuj">New York - USA</p>
                    <div class="row gtuj">
                      {timeSlots.map((slot, index) => (
                        <div class="col-3 col-lg-3 ps-0" key={index}>
                          <div class="ugbj">
                            <button
                              className={`dertotg ${
                                selectedTimeSlots.has(index.toString())
                                  ? "selected dorht"
                                  : ""
                              }`}
                              onClick={() =>
                                handleTimeSlotChange(index.toString())
                              }
                            >
                              <img src={slot.image} alt={slot.label} />
                            </button>
                            <p class="swtsws456">{slot.label}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div class="right-box d-flex align-items-start ">
                  <img class="icon-lerft" src={returnn} alt="" />
                  <div class="right-inner-box">
                    <h6 class="mb-2 font-clrf">Return From</h6>
                    <p class="font-re-s gtuj">Denver - USA</p>
                    <div class="row gtuj">
                      {timeSlotsForReturn.map((slot, index) => (
                        <div class="col-3 col-lg-3 ps-0">
                          <div class="ugbj">
                            <button
                              className={`dertotg ${
                                selectedTimeSlotsForReturn.has(index.toString())
                                  ? "selected dorht"
                                  : ""
                              }`}
                              onClick={() =>
                                handleTimeSlotForReturnChange(index.toString())
                              }
                            >
                              <img src={slot.image} alt={slot.label} />
                            </button>
                            <p class="swtsws456">{slot.label}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div class="right-box d-flex align-items-start ">
                                    <img class="icon-lerft" src={fares} alt="" />
                                    <div class="right-inner-box">
                                        <h6 class="mb-2 font-clrf">Fares</h6>
                                        <div class="d-flex justify-content-between  ">
                                            <p class="aopi46">{`$${priceRange[0]}`}{/*<sup>00</sup></p>
                                            <p class="aopi46">{`$${priceRange[1]}`}{/*<sup>00</sup></p>
                                        </div>
                                        <div class="rela">
                                            <div class="stgt-line"></div>
                                            <div class="cirlfjg" style={{ left: `${(priceRange[0] / 1000) * 100}%` }} draggable="true" onDrag={(e) => handleSliderDrag(e, true)} // Drag for lower bound
                                                onDragEnd={handleDragEnd}></div>
                                            <div
                                                className="cirlfjg"
                                                style={{ left: `${(priceRange[1] / 1000) * 100}%` }}
                                                draggable="true"
                                                onDrag={(e) => handleSliderDrag(e, false)} // Drag for upper bound
                                                onDragEnd={handleDragEnd}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                <div class="right-box d-flex align-items-start ">
                  <img class="icon-lerft" src={airline} alt="" />
                  <div class="right-inner-box">
                    <h6 class="mb-2 font-clrf">Airlines</h6>
                    <div className="d-flex font-re-s align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        {/*<input
                                                    className="form-check-input dfyhdry me-1"
                                                    type="checkbox"
                                                    checked={selectAllAirlines}
                                                    onChange={handleSelectAllChange}
                                                    id="selectAllCheckbox"
                                                />*/}
                        {/* <p className="mb-0 ms-1">Select All</p> */}
                      </div>
                    </div>
                    {visibleAirlines.map((airline, index) => (
                      <div
                        className="d-flex font-re-s align-items-center justify-content-between"
                        key={index}
                      >
                        <div className="d-flex align-items-center">
                          <input
                            className="form-check-input dfyhdry me-1"
                            type="checkbox"
                            value={airline.code}
                            onChange={handleAirlineChange}
                            id={`checkbox-${airline.code}`}
                            checked={selectedAirlines.includes(airline.code)}
                          />
                          <p className="mb-0 ms-1">
                            {airlinesName[airline.code]}
                            {/* Optionally add airline logo */}
                            {/* <img src={`https://imgfolders.com/farehutz/${airline.code}.png`} alt={airline.code} /> */}
                          </p>
                        </div>
                        <p className="mb-0 ms-1">
                          ${airline.price.toLocaleString()}
                        </p>
                      </div>
                    ))}
                    {airlinesWithLowestPrices.length > 5 && (
                      <button
                        className="btn btn-link p-0 mt-2"
                        onClick={() => setShowMore((prev) => !prev)}
                      >
                        {showMore ? "Show Less" : "Show More"}
                      </button>
                    )}
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Filters;
