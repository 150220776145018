/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/css/styles.css'

export default function TermsCondition() {
  return (
    <>
    <div class="main-banner-im bred-ban">
    <section>
     <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="text-white mb-3">Terms & Conditions</h2>
                <ul class="bred">
                  <li><Link to='/'>Home</Link></li>
                    <li>Terms & Conditions</li>
                </ul>
            </div>
        </div>
     </div>
    </section>
    </div>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ding d-none">
                        <h2>Terms of Use</h2>
                        <p>(Effective From: January 1, 2025)</p>
                        <p>By using our website, you agree to abide by the terms and conditions outlined in this Agreement. Each time you access or use the Service, you acknowledge that you have read, understood, and accepted the terms. If you disagree with any part of this Agreement, you must refrain from using the Service.</p>

                        <p>Fareslist provides an online platform to help you find and connect with merchants or service providers who advertise and sell products or services. We do not sell any products or offer services ourselves and are not responsible for the sale or provision of the goods or services listed on our website.</p>

                       
                        <h5 id="hedthj1">Liabilities</h5>
                        <p>Fareslist does not assume responsibility or liability for any products or services listed on the website. If you have questions, complaints, or claims regarding a product or service, you should contact the respective seller or service provider directly. We make no guarantees about the accuracy, completeness, reliability, or currency of prices, shipping fees, taxes, product details, or any other content on the site.</p>
                        <p>The content available on the site is intended solely for informational purposes. Fareslist is not liable for any inaccuracies, incomplete information, user reviews, or other content provided on the website. In the event of a conflict between information on the website and the merchant or service provider's site, the merchant or service provider&#39;s information takes precedence.</p>
                        <p>Fareslist also reserves the right to modify, update, or change any information on the website at its discretion, without notice, and for any reason.</p>
                        <h5 id="hedthj2">Third-Party websites links</h5>
                        <p>Fareslist includes links to third-party websites for your convenience and reference. However, we do not control, endorse, or take responsibility for any content, products, or materials found on these external sites. Accessing these websites and their resources is entirely at your own risk. Upon linking to an external website, we recommend reviewing that site's terms of use and privacy policy to understand their practices.</p>
                        <h5 id="hedthj3">Acceptable use of the website</h5>
                        <p>You are required to use the website in a legal and responsible manner, ensuring that your actions do not compromise the security or integrity of the site. Any behavior that may cause damage to the website or interfere with its availability is strictly prohibited. This includes using the website for unlawful, fraudulent, or harmful activities.</p>
                        <p>Additionally, you must refrain from uploading, storing, or distributing any material that contains harmful software, such as spyware, viruses, or other malicious programs.</p>
                        <p>Automated data collection, including scraping, data mining, and extraction, is not permitted without prior written consent from Fareslist. You must also adhere to the terms outlined in our Privacy Policy, which may be updated periodically.</p>
                        <h5 id="hedthj4">Restricted Access</h5>
                        <p>Certain sections of our website are limited to authorized users only. Fareslist reserves the right to
                        restrict or revoke access to any part of the website, or the entire site, at its discretion.</p>
                       
                        <h5 id="hedthj5">Breaches of These Terms of Use</h5>
                        <p>If you violate any terms outlined in this Agreement, your access to the Service may be immediately revoked. Fareslist reserves the right to modify, suspend, or permanently terminate access to the Service or any part of it at any time, without prior notice, and without incurring any liability to you or others.</p>
                        <p>For any questions or further clarification, please feel free to contact us via email at support@fareslist.com or call us at +1-866-660-6330.</p>
                        
             
    </div>
    <p>(Effective From: January 1, 2025)</p>
    <p>By using our website, you agree to abide by the terms and conditions outlined in this Agreement. Each
time you access or use the Service, you acknowledge that you have read, understood, and accepted the
terms. If you disagree with any part of this Agreement, you must refrain from using the Service.</p>
<p>Fareslist provides an online platform to help you find and connect with merchants or service providers
who advertise and sell products or services. We do not sell any products or offer services ourselves and
are not responsible for the sale or provision of the goods or services listed on our website.</p>
<h5 id="hedthj5">Liabilities</h5>
<p>Fareslist does not assume responsibility or liability for any products or services listed on the website. If
you have questions, complaints, or claims regarding a product or service, you should contact the
respective seller or service provider directly. We make no guarantees about the accuracy, completeness,
reliability, or currency of prices, shipping fees, taxes, product details, or any other content on the site.</p>
<p>The content available on the site is intended solely for informational purposes. Fareslist is not liable for
any inaccuracies, incomplete information, user reviews, or other content provided on the website. In
the event of a conflict between information on the website and the merchant or service provider&#39;s site,
the merchant or service provider&#39;s information takes precedence.</p>
<p>Fareslist also reserves the right to modify, update, or change any information on the website at its
discretion, without notice, and for any reason.</p>


<h5 id="hedthj5">Third-Party websites links</h5>
<p>Fareslist includes links to third-party websites for your convenience and reference. However, we do not
control, endorse, or take responsibility for any content, products, or materials found on these external
sites. Accessing these websites and their resources is entirely at your own risk. Upon linking to an
external website, we recommend reviewing that site’s terms of use and privacy policy to understand
their practices.</p>
    
<h5 id="hedthj5">Acceptable use of the website</h5>

<p>You are required to use the website in a legal and responsible manner, ensuring that your actions do not
compromise the security or integrity of the site. Any behavior that may cause damage to the website or
interfere with its availability is strictly prohibited. This includes using the website for unlawful,
fraudulent, or harmful activities.</p>

<p>Additionally, you must refrain from uploading, storing, or distributing any material that contains harmful
software, such as spyware, viruses, or other malicious programs.</p>

<p>Automated data collection, including scraping, data mining, and extraction, is not permitted without
prior written consent from Fareslist. You must also adhere to the terms outlined in our Privacy Policy,
which may be updated periodically.</p>

<h5 id="hedthj5">Restricted Access</h5>

<p>Certain sections of our website are limited to authorized users only. Fareslist reserves the right to
restrict or revoke access to any part of the website, or the entire site, at its discretion.</p>

<h5 id="hedthj5">Breaches of These Terms of Use</h5>

<p>If you violate any terms outlined in this Agreement, your access to the Service may be immediately
revoked. Fareslist reserves the right to modify, suspend, or permanently terminate access to the Service
or any part of it at any time, without prior notice, and without incurring any liability to you or others.</p>

<p>For any questions or further clarification, please feel free to contact us via email at
support@fareslist.com or call us at +1-866-660-6330.</p>
                    <a id="button2424"></a>
                </div>
                
            </div>
        </div>
    </section>
    </>
  )
}