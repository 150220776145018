import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/css/styles.css';

export default function Privacy() {
  return (
    <>
    <div class="main-banner-im bred-ban">
    <section>
     <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="text-white mb-3">Privacy Policy</h2>
                <ul class="bred">
                  <li><Link to='/'>Home</Link></li>
                    <li>Privacy Policy</li>
                </ul>
            </div>
        </div>
     </div>
    </section>
    </div>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ding d-flex d-none">
                        <ul class="linfg">
                            <li><a href="#hedthj1">Data We Gather Concerning You and Its Method of Acquisition</a></li>
                            <li><a href="#hedthj2">Data Gathering Process</a></li>
                            <li><a href="#hedthj3">Data You Furnish to Us</a></li>
                            <li><a href="#hedthj4">Your Voluntary Contributions and Website Usage</a></li>
                            <li><a href="#hedthj5">Automatic Data Gathering Techniques and Your Online Interaction</a></li>
                            <li><a href="#hedthj6">Methods Employed for Automatic Data Collection</a></li>
                            <li><a href="#hedthj7">Cookie Usage and Its Varied Roles</a></li>
                            <li><a href="#hedthj8">Utilization of Cookies and Tracking Technologies by Third Parties</a></li>
                            <li><a href="#hedthj9">Utilization of Your Data</a></li>
                            <li><a href="#hedthj10">Revealing Your Information</a></li>
                        </ul>
                        <ul class="linfg">
                            
                            <li><a href="#hedthj11">Necessitated Personal Data Sharing for Legal Compliance</a></li>
                            <li><a href="#hedthj12">Exercising Influence Over Information Handling and Disclosure</a></li>
                            <li><a href="#hedthj13">Accessing and Rectifying Your Data</a></li>
                            <li><a href="#hedthj14">Ensuring Information Security</a></li>
                            <li><a href="#hedthj15">Preservation of Your Data</a></li>
                            <li><a href="#hedthj16">Handling Your Data</a></li>
                            <li><a href="#hedthj17">Global Data Movement</a></li>
                            <li><a href="#hedthj18">Notification of Rights for Individuals within the EU</a></li>
                            <li><a href="#hedthj19">Notice to Canadian Residents Regarding Your Rights</a></li>
                        </ul>
                        <ul class="linfg">
                            
                            <li><a href="#hedthj20">For your request to be valid:</a></li>
                            <li><a href="#hedthj21">Notice to Residents of California; California Consumer I Notification:</a></li>
                            <li><a href="#hedthj22">Notice to Residents of Nevada; Nevada Privacy Rights Notification:</a></li>
                            <li><a href="#hedthj23">Notice to Residents of Virginia; Virginia Privacy Rights Notification:</a></li>
                            <li><a href="#hedthj24">Notice to Residents of Colorado; Colorado Privacy Rights Notification:</a></li>
                            <li><a href="#hedthj25">Notice to Residents of Connecticut; Connecticut Privacy Rights Notification:</a></li>
                            <li><a href="#hedthj26">Updates to Our Privacy Policy:</a></li>
                            <li><a href="#hedthj27">Contact Details:</a></li>
                        </ul>
                    </div>
                    <p>Fareslist is deeply committed to protecting your privacy. This policy outlines how we handle the
                    collection, usage, and safeguarding of personal information shared by visitors on our site.</p>

                    <h5 id="hedthj5">Logs</h5>

                    <p>We utilize IP addresses to study trends, manage the website, monitor user activity, and collect general
data for analysis. These IP addresses are not connected to any data that can identify individuals. By
continuing to use this website, you acknowledge and agree to the terms outlined in this policy.</p>

<h5 id="hedthj5">Email</h5>

<p>By opting in to receive promotional or informational emails from Fareslist, you agree to occasionally
receive advertisements, marketing content, or special offers that we believe may be relevant to you.
These communications are sent directly by Fareslist, and we do not share your email address with third-
party businesses. If you no longer wish to receive these emails, you can easily opt out by clicking the
unsubscribe link included in every email we send.</p>

<h5 id="hedthj5">Information we collect from you</h5>

<p>We gather information when you register on our site, subscribe to our newsletter, or input details while
interacting with our website. This includes any data you provide that could personally identify you
(&quot;Personally Identifiable Information&quot; or &quot;PII&quot;). Such information may include your first and last name,
email address, date of birth, geo-location, phone number, and other similar details.</p>

<h5 id="hedthj5">Children&#39;s Privacy</h5>

<p>Fareslist does not intend for any part of this site to be accessed or used by individuals under the age of
eighteen, and such use is strictly prohibited. By accessing and using the site, you confirm that you are at
least eighteen years old and understand that this is a requirement for use.</p>

<h5 id="hedthj5">Future Business Transfers</h5>

<p>If Fareslist undergoes a business transition, such as a merger, acquisition, or sale of all or part of its
assets, your personally identifiable information may be transferred as part of that process. You will be
notified via email or through a prominent notice on our website at least 30 days before any changes in
the ownership or control of your personal information.</p>

<h5 id="hedthj5">Disclosure</h5>

<p>We may disclose your personally identifiable information, including but not limited to your email
address, if we believe it is necessary to comply with legal obligations, regulations, or government
requirements. However, we will never sell your personally identifiable information to any third party. In
the event of a merger, consolidation, or any corporate reorganization involving Fareslist, your personal
data may be transferred to the successor entity or to the purchaser of all or substantially all of our
assets. The successor entity will be bound by the terms outlined in this Privacy Policy.</p>

<h5 id="hedthj5">Cookies</h5>

<p>We use cookies and other technologies to improve your browsing experience and better understand
how you engage with our services. This helps us enhance the quality of the services we offer.</p>

<h5 id="hedthj5">Other Sites</h5>

<p>Fareslist may redirect you to external websites or content providers that match your search. However,
we do not control these sites, and we are not responsible for their actions or privacy practices, even if
you access them through us.</p>
<p>We may share aggregated, non-personally identifiable information about you and other users with our
parent company, affiliates, and third parties to help improve our services. This shared data will not
include any personally identifiable information.</p>

<h5 id="hedthj5">Correction/Updating/Deleting Personally Identifiable Information</h5>
<p>Fareslist allows you to review, correct, and delete any personally identifiable information you have
shared with us. If you wish to update or remove your information, please reach out to us.</p>

<h5 id="hedthj5">Changes to This Privacy Policy</h5>

<p>If we decide to update our privacy policy, we will post the revised version on this page, so you can stay
informed about the data we collect, how we use it, and the circumstances under which we may disclose
it. We reserve the right to modify this policy at any time, so we encourage you to check it regularly. If we
make significant changes, we will notify you via email or by posting an alert on our homepage.</p>
<p>If you have any questions or concerns about privacy practices at Fareslist, feel free to reach out to us.
You can contact us via email at support@fareslist.com or call us at +1-866-660-6330.</p>
                    <a id="button2424"></a>
                </div>
                
            </div>
        </div>
    </section>
    </>
  )
}
