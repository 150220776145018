/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// App.js
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Filters from "../../Components/Filters/filters";
import FlightCard from "../../Components/FlightCard/flightCard";
import Sidebar from "../../Components/SideBar/sideBar";
import Pagination from "../../Components/Pagination/Pagination";
import { constant } from "../../constant";
// import './App.css';
import "../../Resources/css/listing.css";
import ModifySearch from "../../Components/ModifySearch/ModifySearch";
import imgfilter from "../../Resources/assest/img/listing/filter.png";
import JSZip from "jszip";

const Listing = () => {
  const location = useLocation();
  const { updatedFormValues } = location.state || {};
  // console.log('updatedFormValues', updatedFormValues);
  const [isLoding, setIsLoding] = useState(true);
  const [flights, setFlights] = useState([]);
  const [metaFlights, setMetaFlights] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredFlights, setFilteredFlights] = useState(flights);
  const [resultsCount, setResultsCount] = useState(0);
  const [filteredResultsCount, setFilteredResultsCount] = useState(0);
  const [allMeta, setAllMeta] = useState([]);
  const [progress, setProgress] = useState(0); // State for progress
  const [modifyClick, setModifyClick] = useState(false);
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("best");
  const totalExpectedResults = 300; // Replace with actual total if known
  const flightsPerPage = 50; // Set how many flights to display per page

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoding(true);
        // console.log("Form data being sent:", updatedFormValues);

        // First API Call: Fetch itineraries
        // First API Call: Fetch itineraries
        // console.log("Fetching itineraries...");
        const onlyDeeplinkResponse = await fetch(
          `${constant.baseUrl}api/website/get-only-deeplink`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedFormValues),
          }
        );

        // Check response status
        if (!onlyDeeplinkResponse.ok) {
          console.error(
            "Failed to fetch onlyDeeplink. Status:",
            onlyDeeplinkResponse.status
          );
          return;
        }

        // Parse response as JSON
        const onlyDeeplink = await onlyDeeplinkResponse.json();
        // console.log("onlyDeeplink response:", onlyDeeplink);

        // Set `onlyDeeplink` to state
        setAllMeta(onlyDeeplink);

        // const itineraryResponse = await fetch(
        //   `${constant.baseUrl}api/website/itineraries`,
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(updatedFormValues), // Pass the form data
        //   }
        // );
        // console.log("itineraryResponse", itineraryResponse)

        // if (!itineraryResponse.ok) {
        //   console.error(
        //     "Failed to fetch itineraries. Status:",
        //     itineraryResponse.status
        //   );
        //   // return;
        // }

        // const itineraryData = await itineraryResponse.json();
        // // console.log("Itinerary response received:", itineraryData);

        // // Set flights from the itineraries API response
        // if (itineraryData.uniqueItineraries) {
        //   setFlights(itineraryData.uniqueItineraries);
        //   setResultsCount(itineraryData.uniqueItineraries.length); // Set results count based on the initial data
        //   // console.log("Flights set from itineraries API:", itineraryData.uniqueItineraries);
        // }

        const metaResponse = await fetch(
          `${constant.baseUrl}api/website/get-meta-deep-link`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedFormValues), // Pass the form data
          }
        );
        const metaData = await metaResponse.json();
        // console.log("metaData",metaData); // Check what the structure of the metaData is

        // Ensure the data exists before setting it to state
        if (metaData && Array.isArray(metaData)) {
          const filteredMetaData = metaData.filter(
            (item) => item.deeplink && item.deeplink.includes("https://www.")
          );

          if (filteredMetaData.length > 0) {
            // Update the date format in the deeplink
            const updatedMetaData = filteredMetaData.map((item) => {
              const url = new URL(item.deeplink);
              const searchParams = url.searchParams;

              // Helper function to convert date format
              const formatDate = (date) => {
                const [year, month, day] = date.split("-");
                return `${month}/${day}/${year}`;
              };

              // Update departDate
              if (searchParams.has("departDate")) {
                const newDepartDate = formatDate(
                  searchParams.get("departDate")
                );
                searchParams.set("departDate", newDepartDate);
              }

              // Update returnDate
              if (searchParams.has("returnDate")) {
                const newReturnDate = formatDate(
                  searchParams.get("returnDate")
                );
                searchParams.set("returnDate", newReturnDate);
              }

              // Return updated item with the modified deeplink
              return { ...item, deeplink: url.toString() };
            });

            setMetaFlights(updatedMetaData); // Update state with the modified data
          } else {
            console.error(
              "No matching data found with 'https://www.' in deeplink."
            );
          }
        } else {
          console.error("No data found in meta response.");
        }
        console.log("updatedFormValues", updatedFormValues);
        const requestedData = {
          ...updatedFormValues,
          wesbiteId: "1",
        };
        console.log("requestedData", requestedData);
        const response1 = await fetch(
          `${constant.baseUrl}api/website/get-cache-data`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestedData),
          }
        );
        
        const jsonResponse = await response1.json();
        console.log("Frontend received:", jsonResponse);

        if (jsonResponse.flights) {
            setFlights(jsonResponse.flights);
        }

        const response = await fetch(
          `${constant.baseUrl}api/website/get-deep-link`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedFormValues),
          }
        );

        // console.log("Response status:", response.status); // Check if the response is successful
        // console.log("Response body exists:", !!response.body);

        if (!response.body) {
          console.error(
            "ReadableStream not supported or response body is null"
          );
          return;
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let buffer = ""; // Buffer to accumulate incoming chunks
        let flights = []; // To store accumulated flight data
        let itineraryBuffer = ""; // Buffer for the current itinerary

        let timedOut = false;
        const timeoutId = setTimeout(() => {
          timedOut = true;
          console.log("Streaming stopped after 30 seconds.");
        }, 30000);

        while (true) {
          if (timedOut) {
            console.log("Timeout reached. Exiting streaming loop.");
            break; // Exit the loop when timeout is reached
          }
          const { done, value } = await reader.read();

          if (done) {
            // console.log("Stream has finished.");
            setProgress(100);
            break;
          }

          // console.log("Received chunk from stream:", value);

          // Decode the chunk to a string and add to the buffer
          buffer += decoder.decode(value, { stream: true });
          // console.log("Buffer after decoding chunk:", buffer);

          // Split the buffer into individual chunks based on the incoming format
          const chunks = buffer.split("data:");

          for (let chunk of chunks) {
            if (!chunk.trim()) continue; // Ignore empty chunks

            // Accumulate itineraries
            itineraryBuffer += chunk.trim(); // Accumulate current itinerary data

            // Check for complete itinerary JSON object
            const closingBraces = (itineraryBuffer.match(/\}/g) || []).length; // Count closing braces
            const openingBraces = (itineraryBuffer.match(/\{/g) || []).length; // Count opening braces

            // If we have matching braces, we have a complete itinerary
            if (closingBraces > 0 && closingBraces === openingBraces) {
              try {
                // Parse the accumulated itinerary
                const flightData = JSON.parse(itineraryBuffer);
                // console.log("Parsed flight data:", flightData);
                // Check if flightItineraries exist in the flightData
                if (flightData.uniqueItineraries) {
                  // Sort itineraries by price before setting them in state
                  // const sortedItineraries = flightData.uniqueItineraries.sort((a, b) => {
                  //     return a.price.totalAmount - b.price.totalAmount; // Ascending order by price
                  // });

                  const sortedItineraries = flightData.uniqueItineraries.sort(
                    (a, b) => {
                      // Calculate total trip time for each itinerary
                      const totalEftA =
                        (a.leg1?.totalEft || 0) + (a.leg2?.totalEft || 0);
                      const totalEftB =
                        (b.leg1?.totalEft || 0) + (b.leg2?.totalEft || 0);

                      // Calculate a combined score (weighting price and time equally, or adjust weights if needed)
                      const scoreA = a.price.totalAmount + totalEftA; // Combined score for A
                      const scoreB = b.price.totalAmount + totalEftB; // Combined score for B

                      // Sort by combined score (lower is better for both price and time)
                      return scoreA - scoreB;
                    }
                  );

                  // Update the flights state
                  setFlights([...sortedItineraries]);

                  // Update results count
                  const newItinerariesCount =
                    flightData.uniqueItineraries.length;
                  setResultsCount(
                    (prevCount) => newItinerariesCount
                  );

                  // Update progress
                  const newProgress = Math.min(
                    ((resultsCount + newItinerariesCount) /
                      totalExpectedResults) *
                      100,
                    100
                  );
                  setProgress(newProgress);

                  console.log("Flights updated:", sortedItineraries);
                }
              } catch (error) {
                console.error("Error parsing flight data:", error);
              }

              // Clear the itinerary buffer after parsing
              itineraryBuffer = "";
            }
          }

          // Clear the buffer after processing all chunks
          buffer = "";
        }
        
      } catch (e) {
        console.error("Error fetching flight data:", e);
      } finally {
        setIsLoding(false);
      }
    };

    fetchData(); // Call the inner async function
  }, [updatedFormValues]);
  useEffect(() => {
    if (flights.length > 0) {
      setFilteredFlights(flights); // Set filteredFlights with all flights initially
      setFilteredResultsCount(flights.length); // Update filteredResultsCount
    }
  }, [flights]);

  useEffect(() => {
    if (flights.length === 0) return;
    const saveData = async () => {
    console.log("All flights received:", flights);
        // **Step 2: Convert Flights Data to a ZIP File**
        const zip = new JSZip();
        zip.file("flights.json", JSON.stringify(flights, null, 2));
        const zipBlob = await zip.generateAsync({ type: "blob" });

        // Convert Blob to Base64
        const zipBase64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(zipBlob);
          reader.onloadend = () => {
            resolve(reader.result.split(",")[1]); // Extract only Base64 content
          };
        });

        const requestSaveCacheResponse = {
          ...updatedFormValues,
          wesbiteId: "1",
          cacheResponse: zipBase64,
        };
        console.log("requestSaveCacheResponse", requestSaveCacheResponse);
        // **Step 3: Call Second API to Save Cache Response**
        const saveResponse = await fetch(
          `${constant.baseUrl}api/website/save-cache-data`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestSaveCacheResponse),
          }
        );

        if (!saveResponse.ok) {
          console.error("Failed to save cache response");
        } else {
          console.log("Cache response saved successfully");
        }
    };

    saveData();
  }, [flights]);

  const calculateAveragePrice = (flights) => {
    // if (flights.length === 0) return "0.00"; // Guard clause for empty flights array
    // const total = flights.reduce(
    //   (acc, flight) => acc + flight.price.totalAmount,
    //   0
    // );
    // return (total / flights.length).toFixed(2);
    if (flights.length === 0) return "0.00"; // Guard clause for empty flights array
    return Math.min(...flights.map((flight) => flight.price.totalAmount));
  };

  const calculateMinPrice = (flights) => {
    if (flights.length === 0) return "0.00"; // Guard clause for empty flights array
    return Math.min(...flights.map((flight) => flight.price.totalAmount));
  };

  const calculateMaxPrice = (flights) => {
    if (flights.length === 0) return "0.00"; // Guard clause for empty flights array

    // Find the fastest flight based on calculated duration
    const fastestFlight = flights.reduce((fastest, flight) => {
        const duration = flight.calculatedDuration ?? Infinity;
        return duration < (fastest.calculatedDuration ?? Infinity) ? flight : fastest;
    }, flights[0]); // Initialize with the first flight

    return fastestFlight?.price?.totalAmount ?? "0.00";
};

  const calculateAndFilterFlights = (criteria) => {
    console.log("Filtering flights based on:", criteria); // Debugging

    if (!filteredFlights || filteredFlights.length === 0) {
        console.log("No flights available to filter.");
        return;
    }

    let sortedFlights = [...filteredFlights]; // Copy array

    // Calculate durations and add to each flight
    const calculateDuration = (flight) => {
        if (flight?.leg1 && flight?.leg1?.segments && flight?.leg2 && flight?.leg2?.segments) {
            // Get the first segment of the first leg (departure)
            const firstSegment = flight?.leg1?.segments[0];
    
            // Get the last segment of the second leg (return)
            const lastSegment = flight.leg2.segments[flight.leg2.segments.length - 1];
    
            console.log("First Segment:", firstSegment); // Debugging
            console.log("Last Segment:", lastSegment);   // Debugging
    
            if (firstSegment && lastSegment) {
                const departureTimeStr = firstSegment.departureDateTime;
                const arrivalTimeStr = lastSegment.arrivalDateTime;
    
                // Log the raw string values
                console.log("Departure DateTime String:", departureTimeStr);
                console.log("Arrival DateTime String:", arrivalTimeStr);
    
                // Parse the date strings
                const departureTime = new Date(departureTimeStr).getTime(); // Departure time of first segment
                const arrivalTime = new Date(arrivalTimeStr).getTime(); // Arrival time of last segment
    
                console.log("Parsed Departure Time:", departureTime); // Debugging
                console.log("Parsed Arrival Time:", arrivalTime);     // Debugging
    
                if (!isNaN(departureTime) && !isNaN(arrivalTime)) {
                    return (arrivalTime - departureTime) / (1000 * 60); // Duration in minutes
                } else {
                    console.log("Invalid departure or arrival time found.");
                }
            }
        }
    
        return Infinity; // Default to a high value if no valid duration is found
    };

    // Calculate and assign duration to each flight
    sortedFlights = sortedFlights.map((flight) => ({
        ...flight,
        calculatedDuration: calculateDuration(flight),
    }));

    console.log("Flights before sorting:", sortedFlights);

    // Apply sorting based on criteria
    switch (criteria) {
      case "best":
          sortedFlights.sort((a, b) => {
              const priceA = a.price?.totalAmount ?? Infinity;
              const priceB = b.price?.totalAmount ?? Infinity;

              // Log for debugging
              console.log("Comparing prices:", priceA, priceB);

              if (priceA === priceB) {
                  const durationA = a.calculatedDuration ?? Infinity;
                  const durationB = b.calculatedDuration ?? Infinity;
                  console.log("Comparing durations:", durationA, durationB);
                  
                  // If prices are equal, compare by duration
                  if (durationA === durationB) {
                      return 0; // If both price and duration are equal, return 0 to keep their order intact
                  }
                  return durationA - durationB; // Sort by duration
              }
              return priceA - priceB; // Sort by price first
          });

          // Calculate Average Price for "best" criteria
          const averagePrice = calculateAveragePrice(sortedFlights);
          console.log(`Average Price for Best Criteria: $${averagePrice}`);
          break;

      case "cheapest":
          sortedFlights.sort((a, b) => a.price.totalAmount - b.price.totalAmount);

          // Calculate Minimum Price for "cheapest" criteria
          const minPrice = calculateMinPrice(sortedFlights);
          console.log(`Min Price for Cheapest Criteria: $${minPrice}`);
          break;

      case "fastest":
          sortedFlights.sort((a, b) => {
              const durationA = a.calculatedDuration ?? Infinity;
              const durationB = b.calculatedDuration ?? Infinity;
              return durationA - durationB;
          });

          // Calculate Maximum Price for "fastest" criteria
          const maxPrice = calculateMaxPrice(sortedFlights);
          console.log(`Max Price for Fastest Criteria: $${maxPrice}`);
          break;

      default:
          console.log("Invalid filter criteria:", criteria);
          return;
  }

    // Ensure state updates correctly
    setFilteredFlights([...sortedFlights]); // Force state update
    setFilteredResultsCount(sortedFlights.length);
};


  // console.log("FilteredFlights", flights)

  const indexOfLastFlight = currentPage * flightsPerPage;
  const indexOfFirstFlight = indexOfLastFlight - flightsPerPage;
  const currentFlights = filteredFlights.slice(
    indexOfFirstFlight,
    indexOfLastFlight
  );

  // Pagination button click handler
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredFlights.length / flightsPerPage);
  // const flights = [
  //   { airline: 'Delta', price: 318, duration: '4h 54m', stops: 'Direct' },
  //   { airline: 'Delta', price: 318, duration: '4h 54m', stops: 'Direct' },
  //   { airline: 'Delta', price: 318, duration: '4h 54m', stops: 'Direct' }
  //   // Add more flights as needed
  // ];

  

  const handleFilterMobile = () => {
    setMobileFilterOpen(!mobileFilterOpen);
  };

  return (
    <>
      <div onClick={() => setModifyClick(true)}>
        <ModifySearch
          onClick={modifyClick}
          initialFormValues={updatedFormValues}
        />
      </div>
      <section className="main-body-listing pt-0">
        <div class="container">
          <div class="row">
            {currentFlights.length > 0 ? (
              <>
                <div
                  class="mob-filter text-center"
                  onClick={handleFilterMobile}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal12"
                >
                  {mobileFilterOpen ? (
                    <h6 class="mb-0 dsfhy d-flex align-items-center">
                      <img class="icon-lerft" src={imgfilter} alt="" />
                      Close Filter<p></p>
                    </h6>
                  ) : (
                    <h6 class="mb-0 dsfhy d-flex align-items-center">
                      <img class="icon-lerft" src={imgfilter} alt="" />
                      Filter<p></p>
                    </h6>
                  )}
                </div>
              </>
            ) : null}
            <Filters
              flights={flights}
              filteredResultsCount={filteredResultsCount}
              onFilterChange={setFilteredFlights}
              mobileFilter={mobileFilterOpen}
              initialFormValues={updatedFormValues}
            />

            <div class="col-lg-6 hutd6">
              <div class="d-flex align-items-center">
                {isLoding ? (
                  <div class="spinner12 center">
                    <div class="spinner-blade"></div>
                    <div class="spinner-blade"></div>
                    <div class="spinner-blade"></div>
                    <div class="spinner-blade"></div>
                    <div class="spinner-blade"></div>
                    <div class="spinner-blade"></div>
                    <div class="spinner-blade"></div>
                    <div class="spinner-blade"></div>
                    <div class="spinner-blade"></div>
                    <div class="spinner-blade"></div>
                    <div class="spinner-blade"></div>
                    <div class="spinner-blade"></div>
                  </div>
                ) : null}
                <p class="mb-0 ms-2">{resultsCount} results</p>
              </div>
              <div className="progress mt-3">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${progress}%` }} // Dynamic width based on progress
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              {/* <!-- ..............compare button start................ --> */}
              <div class="d-flex mt-3 ur85">
                <button
                  class={`wrh45 border-0 ${activeFilter === "best" ? "activatefd" : ""}`}
                  onClick={() => {calculateAndFilterFlights("best"); setActiveFilter("best");}}
                >
                  <p class="mb-1 rfgrt">Best</p>
                  <p class="mb-0 pricve">
                    ${Math.round(calculateAveragePrice(filteredFlights))}
                  </p>
                </button>
                <button
                  class={`wrh45 ${activeFilter === "cheapest" ? "activatefd" : ""}`}
                  onClick={() => {calculateAndFilterFlights("cheapest"); setActiveFilter("cheapest");}}
                >
                  <p class="mb-1 rfgrt">Cheapest</p>
                  <p class="mb-0 pricve">
                    ${Math.round(calculateMinPrice(filteredFlights))}
                  </p>
                </button>
                <button
                  class={`wrh45 ${activeFilter === "fastest" ? "activatefd" : ""}`}
                  onClick={() => {calculateAndFilterFlights("fastest"); setActiveFilter("fastest");}}
                >
                  <p class="mb-1 rfgrt">Fastest</p>
                  <p class="mb-0 pricve">
                    ${Math.round(calculateMaxPrice(filteredFlights))}
                  </p>
                </button>
              </div>
              {/* <!-- ..............compare button end................ --> */}
              {currentFlights.map((flight, index) => {
                const flightWithLoading = {
                  ...flight,
                  isLoding,
                  index,
                  allMeta,
                  updatedFormValues
                }; // Declare your variable here
                return <FlightCard key={index} {...flightWithLoading} />;
              })}
            </div>
            <Sidebar metaFlights={metaFlights} />
          </div>
          {/* Pagination Controls */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            paginate={paginate}
          />
        </div>
      </section>
    </>
  );
};

export default Listing;
